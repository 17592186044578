.chat-container {
  width: 400px; /* Задаем ширину контейнера 350px */
  height: 85vh; /* Задаем высоту 85% от высоты видимой области экрана */
  border: 1px solid black; /* Добавляем черную линию окантовки шириной 1px */
}

/* Медиа-запрос для изменения стилей при ширине экрана меньше 991px */
@media (max-width: 991px) {
  .chat-container {
    align-items: center; /* Центрирует элементы по горизонтали */
    justify-content: center; /* Центрирует элементы по вертикали */

    width: 95%; /* Ширина контейнера займет всю доступную ширину при ширине экрана меньше 991px */
    height: 40vh; /* Изменяем высоту на автоматическую */
  }
}


.video-container2 {
  position: absolute;
  width: 80%;
  height: 60%;
  margin-bottom: 5px;
  /* width: 350px; /* Задаем ширину контейнера 350px */
  /* height: 85vh; /* Задаем высоту 85% от высоты видимой области экрана */
  /*border: 1px solid black; */
}

/* Медиа-запрос для изменения стилей при ширине экрана меньше 991px */
@media (max-width: 991px) {
  .video-container2 {
    
    align-items: center; /* Центрирует элементы по горизонтали */
    justify-content: center; /* Центрирует элементы по вертикали */

    width: 95%; /* Ширина контейнера займет всю доступную ширину при ширине экрана меньше 991px */
    height: 100%; /* Изменяем высоту на автоматическую */
    margin-bottom: 5px; 
  }
}

.videoChatContainerStyle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-top: 5px; /* Добавляем отступ сверху */
}

@media (max-width: 991px) {
  .videoChatContainerStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto; /* Изменим высоту на auto для адаптивности */
    width: 95%;
    margin-top: 5px; /* Добавим отступ сверху, чтобы сохранить прежний отступ */
  }
}


.containerStyleBar  {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  
}

@media (max-width: 991px) {
  .containerStyleBar  {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center; /* Центрирует элементы по горизонтали */ 
    
  }
}


.containerStyle  {
  display: flex;
  flex-direction: column;
  justify-content: top; 
  width: 60%;
  height: 100%;
}

@media (max-width: 991px) {
  .containerStyle  {
    display: flex;
    flex-direction: column;
    align-items: center; /* Центрирует элементы по горизонтали */
    justify-content: center; /* Центрирует элементы по вертикали */
    width: 100%;
    height: 100%;
  }
}