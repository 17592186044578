.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;
  }
  
  .form-container {
    text-align: center;
  }
  
  .tab-group {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .tab-header {
    padding: 10px 20px;
    background-color: #f2f2f2;
    cursor: pointer;
    border: 1px solid #cfcfcf;
    border-bottom: none;
  }
  
  .tab-header.active {
    background-color: #fff;
    border-bottom: 2px solid #1a237e;
  }
  
  .input-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  input {
    margin-bottom: 10px;
    padding: 8px;
    width: 200px;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
  }
  
  .action-button {
    background-color: #1a237e;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .action-button:hover {
    background-color: #000051;
  }
  